import styled from "styled-components";
import device from "../global/mediaQueries";
import BG1 from "../assets/img/bg-1.jpg";

const HeroContent = styled.div`
  display: flex;
  align-items: left;
  flex-direction:column;
  gap: ${(props) => props.theme.space.s7};
  max-width: 1376px;
  width:100%;
  background: ${(props) => props.theme.colors.keyline};
  background-image: url(${BG1});
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  border: 16px solid ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.radius.xxl};
  padding: 120px 80px;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 0.06), 0px 4px 4px rgb(0 0 0 / 0.05);

  h1 {
    font-weight: 400;
    font-size: 64px;
    line-height: 82px;
   
    b {
      font-weight: 700;
    }
    @media ${device.laptop} {
      font-size: 44px;
      line-height: 48px;
    }
  }
  h2, h3, h4, h5, p {
    color: ${(props) => props.theme.colors.black};
    font-weight:normal;
  }
@media ${device.laptop} {
  border: none;
  border-radius: 0;
  padding: ${(props) => props.theme.space.s8} ${(props) => props.theme.space.s6};
  box-shadow: none;
}

  
`;

export default HeroContent;
