
import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    accent: "#A9A8A6",
    white: "#FFFFFF",
    divider: "#f1f1ef",
    keyline: "#E1E5E7",
    lightGrey: "#E1DFDE",
    midGrey: "#82898B",
    black: "#1D2332",
  },
  space: {
    s1:"0.25rem",
    s2: "0.5rem",
    s3:"0.75rem",
    s4:"1rem",
    s5:"1.5rem",
    s6:"2rem",
    s7:"2.74rem",
    s8:"4rem",
    s9:"5rem",
  },
  radius: {
    xs:"4px",
    s: "8px",
    m:"16px",
    l:"24px",
    xl:"32pxm",
    xxl:"40px",
  }
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;