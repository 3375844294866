import styled from "styled-components";
import device from "../global/mediaQueries";

const HeroWrap = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  padding: 100px ${(props) => props.theme.space.s6} 0 ${(props) => props.theme.space.s6};

  small {
    max-width: 1200px;
    width: 100%;
    color: ${(props) => props.theme.colors.black};
  }

  @media ${device.laptop} {
    padding: 68px 0 0 0px;
  }
`;

export default HeroWrap;
