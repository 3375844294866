import styled from "styled-components";

const StyledButton = styled.button`
display: flex;
height: 48px;
max-width:480px;
min-width:160px;
justify-content: center;
align-items: center;
text-decoration: none;
cursor: pointer;
border:none;
background: ${(props) => props.theme.colors.black};
border-radius: 8px;

transition: all 0.5s;
text-decoration: none;
padding: ${(props) => props.theme.space.s4} ${(props) => props.theme.space.s6};

h5,
h4,
p {
  text-align: centre;
  margin: 0;
  color: ${(props) => props.theme.colors.white}!important;
}

&:hover {
  background: #000000;
}
`;

const ScrollToAnchorButton = ({ targetId, label }) => {
  const handleClick = () => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <StyledButton onClick={handleClick}>
      <p>{label}</p>
    </StyledButton>
  );
};


export default ScrollToAnchorButton;
