import VwfsHero from "../assets/projects/vwfsHero.jpg";
import Vaughan from "../assets/projects/vau-thumb.jpg";
import DevyThumb from "../assets/projects/devy-thumb.jpg";
import RNThumb from "../assets/projects/rn5.jpg";
import FurtherThumb from "../assets/projects/further-thumb.jpg";
import EVHero from "../assets/projects/evHero.jpg";
import PLPB from "../assets/projects/plpb-thumb.jpg";
import DO from "../assets/projects/do-thumb.jpg";
import RAC from "../assets/projects/rac-thumb.jpg";
import MyRAC from "../assets/projects/myrac-thumb.jpg";
import TCO from "../assets/projects/tco-thumb.jpg";
import JPP from "../assets/projects/jpp-thumb.jpg";
import Arr from "../assets/projects/arr-thumb.jpg";
import DSD from "../assets/projects/dsd-thumb3.jpg";

export const CaseStudies = [
  {
    img: DSD,
    client: "Various",
    desc: "Defining, building and managing design systems",
    types: ["Design Systems", "UI", "UX", "DesignOps"],
    to: "/pages/dsd",
  },
  {
    img: MyRAC,
    client: "Automotive services org",
    types: ["App", "UI", "UX", "Discovery"],
    desc: "An automotive services companion app",
    to: "/pages/myrac",
  },
  {
    img: VwfsHero,
    client: "Volkswagen FS",
    desc: "Car leasing platform for Volkswagen Group",
    types: ["Web", "UI", "UX", "Discovery"],
    to: "/pages/vwfs",
  },
  {
    img: RNThumb,
    client: "WRAP",
    types: ["UI", "UX", "Design System", "Web"],
    desc: "Improving recycling habits in the UK",
    to: "/pages/recNow",
  },

  {
    img: EVHero,
    client: "Audi",
    types: ["App", "UI", "UX", "Discovery"],
    desc: "Educating car buyers about EV ownership",
    to: "/pages/ev",
  },
  {
    img: Arr,
    client: "UK Rail Operator",
    types: ["UI", "UX", "Discovery"],
    desc: "Improving the rail travel experience for d/Deaf passengers",
    to: "/pages/arr",
  },
  {
    img: TCO,
    client: "Rail Operator",
    types: ["Web", "UI", "UX", "Discovery"],
    desc: "Total cost of ownership tool for Electric Vehicles",
    to: "/pages/tco",
  },
  {
    img: RAC,
    client: "Automotive services org",
    types: ["DesignOps", "Design System", "Design Thinking"],
    desc: "Bringing DesignOps to an automotive services provider",
    to: "/pages/rac",
  },
  {
    img: JPP,
    client: "Game Publisher",
    desc: "Imagining the future of game launchers",
    types: ["UI", "UX", "Design System", "Visual Design"],
    to: "/pages/jpp",
  },
  {
    img: DevyThumb,
    client: "Devyce App",
    desc: "Virtual work phone app for UK telecoms operator",
    types: ["UI", "UX", "Visual Design", "App"],
    to: "/pages/devyce",
  },
  {
    img: PLPB,
    client: "Futurice",
    types: ["Design System", "Thought Leadership", "DesignOps"],
    desc: "Pattern Library Playbook thought leadership",
    to: "/pages/plpb",
  },
  {
    img: FurtherThumb,
    client: "Further Type",
    desc: "Creating the Further Type foundry",
    types: ["Typography", "Branding"],
    to: "/pages/further",
  },
  {
    img: DO,
    client: "Futurice",
    types: ["Thought Leadership", "DesignOps"],
    desc: "DesignOps - Small team starter guide",
    to: "/pages/designOps",
  },
  {
    img: Vaughan,
    client: "Vaughan Designs",
    types: ["Web", "UI", "UX", "Visual Design"],
    desc: "Luxury interior design eCommerce platform",
    to: "/pages/vaughan",
  },
];

export default {
  CaseStudies,
};
