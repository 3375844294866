import styled from "styled-components";
import HeroWrap from "./layout/heroWrap";
import HeroContent from "./layout/heroContent";
import LeadLabel from "./layout/leadLabel";
import CtaGroup from "./layout/ctaGroup";
import Foot from "./layout/footer";
import SectionWrap from "./layout/sectionWrap";
import SectionContent from "./layout/sectionContent";
import Divider from "./layout/divider";
import Spacer from "./layout/spacer";
import ActionBtn from "./nav/actionBtn";
import ScrollToAnchorButton from "./nav/scrollToAnchorButton";
import SecondaryBtn from "./nav/secondaryBtn";
import ProjectCards from "./cards/projectCards";
import hello from "./assets/icons/hello.svg";
import ClientCards from "./layout/clientCards";
import Tag from "./layout/tag";
import TagWrap from "./layout/tagWrap";


function App() {
  return (
    <div>
      <HeroWrap>
        <div></div>
        <HeroContent>
          <LeadLabel label="Hi, I'm Thomas" icon={hello} alt="icon" />
          <h1>
            I&apos;m a design <b>leader</b>, <b>thinker</b>, and <b>doer</b>,
            currently working at Futurice
          </h1>

          <CtaGroup>
            <ScrollToAnchorButton targetId="section1" label="My work" />
            <SecondaryBtn label="About me" page="pages/about" />
          </CtaGroup>
        </HeroContent>
        
      </HeroWrap>
      <SectionWrap id="expertise">
        <SectionContent>
          <Spacer mb={60}>
            <h2>Here's what I do</h2>
          </Spacer>
          <Spacer mb={60}>
            <h3>
              UX design <span>UI & Interaction Design</span> Visual Design{" "}
              <span>User Research & Discovery</span> Design Systems{" "}
              <span>Workshop facilitation</span> Team leadership{" "}
              <span>Agile & Scrum</span> Account Development{" "}
              <span>Pitching & Proposals</span> DesignOps <span>Mentoring</span> 
            </h3>
          </Spacer>
          <Spacer mb={40}>
            <p>
              <b>Here’s where I do it</b>
            </p>
            <TagWrap>
              <Tag>
                <small>Automotive</small>
              </Tag>
              <Tag>
                <small>News &amp; Media</small>
              </Tag>
              <Tag>
                <small>Education</small>
              </Tag>
              <Tag>
                <small>Gaming</small>
              </Tag>
              <Tag>
                <small>Public Transport</small>
              </Tag>
              <Tag>
                <small>Climate action</small>
              </Tag>
              <Tag>
                <small>e-commerce</small>
              </Tag>
            </TagWrap>
          </Spacer>
          <ActionBtn label="More about me" page="pages/about" />
        </SectionContent>
      </SectionWrap>
      <Divider />
      <SectionWrap id="section1">
        <SectionContent>
          <Spacer mb={40}>
            <h2>Selected works</h2>
          </Spacer>
          <p>
            Just a heads up, you’ll need to <span>login</span> to view some
            projects
          </p>

          <ProjectCards />
        </SectionContent>
      </SectionWrap>
      <Divider />
      <SectionWrap>
        <SectionContent>
          <Spacer mb={40}>
            <h2>My recent clients</h2>
          </Spacer>
          <Spacer mb={20}>
            <h3>
              Here’s some of the amazing clients I’ve been fortunate enough to
              work with
            </h3>
          </Spacer>
          <Spacer mb={60}>
            <ClientCards></ClientCards>
          </Spacer>
          <ActionBtn label="More about me" page="pages/about" />
        </SectionContent>
      </SectionWrap>
      <Foot />
    </div>
  );
}

export default App;
