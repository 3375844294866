import styled from "styled-components";
import device from "../global/mediaQueries";

const DividerWrap = styled.section`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    padding: 0 ${(props) => props.theme.space.s6};

    @media ${device.laptop} {
        padding: 0;
    }
`

const DividerBG = styled.div`
    width: 100%;
    max-width: 1376px;
    height: ${(props) => props.theme.space.s4};
    border-radius: ${(props) => props.theme.radius.xxl};
    background: ${(props) => props.theme.colors.divider};

    @media ${device.laptop} {
        border-radius: 0;
    }
`

function Divider() {
    return (
        <div>
            <DividerWrap>
                <DividerBG />
            </DividerWrap>
        </div>
    );
}

export default Divider;