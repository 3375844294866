import React from "react";
import styled from "styled-components";
import ActionBtn from "../nav/actionBtn";
import { CaseStudies } from "../global/caseStudies";
import device from "../global/mediaQueries";
import { ProjectCardWrap, CardContentWrap } from "../cards/projectCard";
import Tag from "../layout/tag";
import TagWrap from "../layout/tagWrap";
import Spacer from "../layout/spacer";

const ProjectsWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: stretch;
  width: 100%;
  gap: ${(props) => props.theme.space.s7} ${(props) => props.theme.space.s4};
  margin-top: ${(props) => props.theme.space.s8};

  @media ${device.mobileL} {
    margin-top: 0;
  }
`;

const ProjectCards = () => {
  return (
    <div>
      <ProjectsWrap>
        {CaseStudies.map((CaseStudy) => (
          <ProjectCardWrap to={CaseStudy.to}>
            <CardContentWrap>
              <div className="thumbImg">
                <img src={CaseStudy.img} alt={CaseStudy.client} />
              </div>
              <small><span><b>Client /</b></span> {CaseStudy.client}</small>
              <h4>{CaseStudy.desc}</h4>
              <Spacer mb={10}>
                <TagWrap>
                  {Array.isArray(CaseStudy.types) &&
                    CaseStudy.types.map((type, index) => (
                      <Tag>
                        <small key={index}>{type}</small>
                      </Tag>
                    ))}
                </TagWrap>
              </Spacer>
            </CardContentWrap>
            <ActionBtn page={CaseStudy.to} />
          </ProjectCardWrap>
        ))}
      </ProjectsWrap>
    </div>
  );
};

export default ProjectCards;
