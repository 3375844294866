import React from "react";
import styled from "styled-components";
import HeroWrap from "../layout/heroWrap";
import FootContent from "../layout/footContent";
import IconBtn from "../nav/iconBtn";
import mail from "../assets/icons/mail.svg";
import LeadLabel from "../layout/leadLabel";
import ContactIcon from "../assets/icons/contact.svg";



const ContactBody = styled.p`
    margin-bottom: ${props => props.theme.space.s7};
`;

function Contact() {
  return (
    <div>
      <HeroWrap>
        <small></small>
        <FootContent>
        <LeadLabel label="Contact me" icon={ContactIcon}/>
          <h1>If you’d like to <b>work together</b>, let’s talk</h1>
          
          <IconBtn
            label="thomasweaverdesign@gmail.com"
            icon={mail}
            alt="mail"
          />
        </FootContent>
      </HeroWrap>
    </div>
  );
}

export default Contact;